import { Subscription } from "@rails/actioncable";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const tableRows = document.querySelectorAll('.parking_search_result_row');
    tableRows.forEach(row => {
      row.addEventListener('click', this.handleRowClick.bind(this));
    });

    const tableContinueBtn = document.querySelector("#parking_search_results_continue_btn");
    tableContinueBtn.addEventListener('click', this.handleContinueBtnClick.bind(this));

    const modalCloseBtn = document.querySelector('#modal-close-btn');
    modalCloseBtn.addEventListener('click', this.toggleModalDisplay);

    const modalContinueBtn = document.querySelector('#modal-continue-btn');
    modalContinueBtn.addEventListener('click', this.toggleModalDisplay);
  }

  setRowAsSelected(selectedRow) {
    const tableRows = document.querySelectorAll('.parking_search_result_row');
    tableRows.forEach(tableRow => tableRow.classList.remove('ds_bg-color-cta--d', 'selected-parking'));
    selectedRow.classList.add("ds_bg-color-cta--d", "selected-parking");
  }

  handleRowClick(event) {
    const row = event.target.parentNode;
    this.setRowAsSelected(row);
  }

  handleContinueBtnClick(event) {
    const selectedParkingRow = document.querySelector('.selected-parking');

    if (selectedParkingRow == undefined) {
      alert('Veuillez selectionner un parking');
    }

    // Retrieve data
    const data = selectedParkingRow.dataset;

    // Use data to update modal text
    this.updateModalText(data)

    // Reveal modal
    this.toggleModalDisplay();
  }

  toggleModalDisplay(event) {
    const modal = document.querySelector('#parking_search_results_modal');
    modal.classList.toggle("hide_element")
  }

  updateModalText(data) {
    const {
      parkingId,
      parkingName,
      paymentAmountFrequency,
      priceId,
      subId,
    } = data;

    // fill in blanks on modal form with relevant data
    const modal = document.querySelector('#parking_search_results_modal');
    const parkingNameSpan = modal.querySelector('#parking-name');
    parkingNameSpan.innerText = parkingName;

    const paymentAmountFrequencySpan = modal.querySelector('#monthly-payment');
    paymentAmountFrequencySpan.innerText = paymentAmountFrequency;

    const formValuePriceId = modal.querySelector('.modification-parking-price-id');
    formValuePriceId.value = priceId

    const formValueSubId = modal.querySelector('.modification-parking-sub-id');
    formValueSubId.value = subId

    const formValueParkingId = modal.querySelector('.modification-parking-parking-id');
    formValueParkingId.value = parkingId
  }

  submitNewParking(event) {
    event.preventDefault();
    let subscriptionId = event.target.dataset.currentSubscription;

    let form = document.querySelector(`.modal-form-parking-change-${subscriptionId}`);

    let formData = $(form).serialize();

    $.post("/dashboards/update_modification_parking", formData)
      .done(function(data) {
        // Handle success
      })
      .fail(function(error) {
        // Handle error
      });
  }
}
