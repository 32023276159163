import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import VehiclesController from './vehicles_controller';

export default class extends VehiclesController  {

  static targets = ["info", "manage", "buttonModification", "buttonCancel", "cancel", "cancelHeader",
                    "modal", "return", "methods", "address", "resume", "resiliationEnd", "resiliationEnd30J",
                    "newVehicleForm", "newPlan", "newPrice", "modificationEnd", "modificationOptions",
                    "modificationVehicleOptions", "currentVehicleSize"];

  toggleOtherReasonField() {
    const cancelReasonInputs = document.querySelectorAll("input[name='subscription[cancel_reason]']");

    cancelReasonInputs.forEach((input) => {
      input.addEventListener("change", () => {
        const otherReasonField = document.getElementById("other_reason_field");

        if (input.value === "other_reason") {
          otherReasonField.classList.remove("hide_element");
        } else {
          otherReasonField.classList.add("hide_element");
        }
      });
    });
  }

  toggleDetailAddress(event) {
    var radioBoxElement = event.target;
    if (radioBoxElement.checked == true && radioBoxElement.value == "Par la poste") {
      const container = document.createElement('div');
      container.innerHTML =`<div class="mb-3" id='detail-address-envoi' data-target='subscription.address'>
                            <p>
                              Afin de nous rendre le matériel, dans les plus brefs délais, veuillez svp nous l'envoyer par <b>recommandé avec accusé de réception</b> à l’adresse suivante (et uniquement à cette adresse) :
                            <br>
                              <b>SAS 12.5 25/27 rue du Moulin Joly, 75011 PARIS</b>
                            </p>
                          </div>`;
      event.target.parentNode.appendChild(container.firstChild);

    } else {
      this.addressTargets.forEach((element)=> element.remove());
    }
  }

  hideInfoSubscription() {
    this.infoTargets.forEach((element) => { element.classList.add("hide_element") });
  }

  showResumeSubscription(){
    this.resumeTargets.forEach((element) => { element.classList.remove("hide_element") });
  }

  showManageSubscription() {
    this.manageTargets.forEach((element) => { element.classList.remove("hide_element") });
  }

  hideManageButton() {
    // this.buttonUpdateTarget.classList.add("hide_element");
    this.buttonCancelTarget.classList.add("hide_element");
  }

  hideReturnEquipmentButton(){
    this.returnTarget.classList.add("hide_element");
  }

  showEquipmentReturnMethod(){
    this.methodsTargets.forEach((element) => { element.classList.remove("hide_element") });
  }

  manageSubscription(){
    this.hideInfoSubscription();
    this.showManageSubscription();
    this.showResumeSubscription();
    this.showResiliationEnd();
  }

  selectCancelReason() {
    this.buttonCancelTarget.classList.add("hide_element");
    this.cancelTargets.forEach((element) => { element.classList.remove("hide_element") })
  }

  selectEquipmentReturnMethod() {
    this.hideReturnEquipmentButton();
    this.showEquipmentReturnMethod();
  }

  showResiliationEnd() {
    this.resiliationEndTargets.forEach((element) => { element.classList.remove("hide_element") });
    this.methodsTargets.forEach((element) => {element.classList.add("hide_element") });
    document.querySelector(".modal").classList.add("hide_element");
    // document.querySelector(".automatic_form").classList.add("hide_element")
  }

  showResiliationEnd30J() {
    this.resiliationEnd30JTargets.forEach((element) => { element.classList.remove("hide_element") });
    this.methodsTargets.forEach((element) => {element.classList.add("hide_element") });
    document.querySelector(".modal").classList.add("hide_element");
    document.querySelector(".automatic_form").classList.add("hide_element")
  }

  submitAutomaticCancelForm(event) {
    // event.preventDefault()
    const form = document.querySelector(".automatic_form");
    this.cancelHeaderTarget.classList.add("hide_element");
    const errorMessage = document.getElementById("errorMessage");
    this.showResiliationEnd30J();
    form.submit()
  }

  submitCancelReason(event) {
    event.preventDefault();

    function openModal(){
      $("#cancel_subscription").modal("show");
    }

    const errorFile = "Il faut télécharger un justificatif de résiliation!";
    const errorReason = "Il faut sélectionner une raison de résiliation!";
    const errorFileReason = "Il faut sélectionner une raison de résiliation et télecharger un justificatif"
    const form = event.target.closest('form');
    const errorMessage = form.querySelector("#errorMessage");
    const checkboxes = form.querySelectorAll("input[type='radio']");
    const cancellationProofInput = form.querySelector("input[type='file']");
    let checkboxChecked = false;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkboxChecked = true;
      }
    });

    if (checkboxChecked) {
      if (cancellationProofInput.files.length > 0) {
        openModal();
        errorMessage.innerText = "";
        form.submit();
      }
      else {
        errorMessage.innerText = errorFile;
      }
    } else{
      if (cancellationProofInput.files.length > 0) {
        errorMessage.innerText = errorReason;
      } else {
        errorMessage.innerText = errorFileReason;
      }
    }
  }

  submitReturnMethod(event) {
    // event.preventDefault();
    const errorMethod = "Il faut sélectionner une raison de résiliation!";
    const form = event.target.closest('form');
    const errorMessage = form.querySelector("#errorMessage");
    const checkboxes = form.querySelectorAll("input[type='radio']");
    let checkboxChecked = false;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkboxChecked = true;
      }
    });

    if (checkboxChecked) {
      errorMessage.innerText = "";
      this.showResiliationEnd();
      form.submit();
    } else {
      errorMessage.innerText = errorMethod;
    }
  }

  openModal(){
    $("#cancel_subscription").modal("show");
  }

  // MODIFICATION

  showModificationOptions(event) {
    this.modificationOptionsTargets.forEach((element) => { element.classList.remove("hide_element") });
    this.buttonModificationTargets.forEach((element) => {element.classList.add("hide_element") });
    document.querySelectorAll(".cancel-subscription-header").forEach((element) => element.classList.add("hide_element"));
    document.querySelectorAll(".cancel-subscription-container").forEach((element) => element.classList.add("hide_element"));
  };

  showVehicleOptions(event) {
    this.modificationOptionsTargets.forEach((element) => {element.classList.add("hide_element") });
    this.modificationVehicleOptionsTargets.forEach((element) => { element.classList.remove("hide_element") });
  };

  showNewVehicleForm(event) {

    sessionStorage.setItem("vehicleSize", null);
    sessionStorage.setItem("vehicleGroup", null);
    sessionStorage.setItem("vehicleNumberplate", null);
    sessionStorage.setItem("vehicleColour", null);
    sessionStorage.setItem("vehicleElectric", null);

    this.newVehicleFormTargets.forEach((element) => {element.classList.remove("hide_element") });
    this.modificationVehicleOptionsTargets.forEach((element) => {element.classList.add("hide_element") });
  };

  showModificationPlan(event) {
    event.preventDefault();
    const subscriptionId = event.target.dataset.subscription;

    const form = document.querySelector(`.current-vehicle-form-${subscriptionId}`);

    const formData = $(form).serialize();

    $.get("/dashboards/retrieve_prices.js", formData)
      .done(function(data) {

      })
      .fail(function(error) {

      });
  }


  submitNewVehicleForm(event) {
    event.preventDefault();
    const subscriptionId = event.target.dataset.subscription;

    const form = document.querySelector(`.vehicle-form-${subscriptionId}`);

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_size',
      value: sessionStorage.getItem("vehicleSize")
    }).appendTo(form);

    const formData = $(form).serialize();

    $.get("/dashboards/retrieve_prices.js", formData)
      .done(function(data) {

      })
      .fail(function(error) {

      });
  }

  selectNewPrice(event) {
    let priceCards = document.querySelectorAll(".price-card")
    // Remove the selected class from all price cards
    priceCards.forEach((card) => {
      card.classList.remove("selected");
    });

    // Add the selected class to the clicked price card
    let selectedCard = event.target.closest(".price-card");
    selectedCard.classList.add("selected");

    // Get the price ID of the selected card
    let priceId = selectedCard.dataset.priceId;

    // Set the value of the hidden field to the selected price ID
    let hiddenField = document.getElementById("selected-price-id");
    hiddenField.value = priceId;

    // Set values that form needs to pass to controller
    form = document.querySelector('.change-plan-form');

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_size',
      value: sessionStorage.getItem("vehicleSize")
    }).appendTo('form');

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_group',
      value: sessionStorage.getItem("vehicleGroup")
    }).appendTo('form');

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_numberplate',
      value: sessionStorage.getItem("vehicleNumberplate")
    }).appendTo('form');

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_colour',
      value: sessionStorage.getItem("vehicleColour")
    }).appendTo('form');

    $('<input>').attr({
      type: 'hidden',
      name: 'vehicle_electric',
      value: sessionStorage.getItem("vehicleElectric")
    }).appendTo('form');
  };
}
