import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "container", "hint"];

  copy() {
    this.resetHints();
    var copiedText = this.sourceTarget.innerText;
    var tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.setAttribute("value", copiedText);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.containerTarget.innerHTML += `<span data-target="clipboard.hint">Copié!</span>`;
  }

  resetHints() {
    this.hintTargets.forEach((hintTarget) => {
      hintTarget.remove();
    })
  }
}
